import { Col, Container, FloatingLabel, FormGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Delete() {

  return (
    <Container className='mt-4'>
        <Row>
            <Col>
                        
               <Form>
                    <Row className="mb-3">
                    <Form.Group  controlId="formGridNumber">
                        <Form.Label  className='required' >Consumer No<span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="number"  />
                    </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group  controlId="formGridName">
                        <Form.Label className='required'>Consumer Name<span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="name" />
                    </Form.Group>
                    </Row>
                     
                     
                    <Row className="mb-3">
                    <Form.Group  controlId="formGridNumber">
                        <Form.Label className='required'>Phone Number<span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="number"  />
                    </Form.Group>
                    </Row>
                
                    <Row className="mb-3">
                   <FormGroup  controlId="formGridMessage">
                    <Form.Label>Message</Form.Label> 
                    <FloatingLabel controlId="floatingTextarea2" >
                    <Form.Control
                        as="textarea"
                        placeholder="Leave a message here"
                        style={{ height: '100px' }}/>
                    </FloatingLabel>
                    </FormGroup>
                    </Row>
                    
                    
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                </Form>

            </Col>
        </Row>
    </Container>
  )
}

export default Delete