import React from 'react';
import { Card, Container } from 'react-bootstrap';

const NotificationCard = ({ heading, paragraph, image, link }) => {
  return (
    <Container>
    <Card  className=" col-lg-8 rounded mx-auto mb-3">
      <Card.Body>
        <Card.Title>{heading}</Card.Title>
        <p>{paragraph}</p>
        {image}
        <p>{link}</p>
      </Card.Body>
    </Card>
    </Container>
  );
};

export default NotificationCard;
