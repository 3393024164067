import { Col, Container, Image, Row, Table } from 'react-bootstrap';

function Legal() {
  return (
   <Container>
  
      <Col className=' receiver text-center'><h2>Legal</h2></Col>
    
    
      <Col className='mb-2 text-white text-uppercase'><u>Company Details</u></Col>
  

      <Table striped bordered hover className='table custome-table bg-transparent'>
    
      <tbody>
        <tr>
          <td>Company Name	</td>
          <td className='text-end'>PRASANTH PANACHIKKAL ENTERPRISES PVT LTD</td>
      
        </tr>
        <tr>
          <td>Office Address</td>
          <td className='text-end'>PRASANTH PANACHIKKAL ENTERPRISES PVT LTD<br/>
              NP TOWER<br/>
              1ST FLOOR<br/>
              ROOM NO: 23/326/53<br/>
              WEST FORT, THRISSUR<br/>
              KERALA , INDIA
              PIN CODE: 680004</td>
        
        </tr>
        <tr>
          <td>GST NUMBER</td>
          <td className='text-end'>32AAICP8972D1Z9.</td>
      
        </tr>
        <tr>
          <td>PAN CARD</td>
          <td className='text-end'>AAICP8972D</td>
      
        </tr>
        <tr>
          <td>CIN</td>
          <td className='text-end'>U72200KL2016PTC046962</td>
      
        </tr>
        <tr>
          <td>Website</td>
          <td className='text-end'>giventake.world</td>
      
        </tr>
    
      </tbody>
    </Table>

   
 
      

        <Row className='text-white  '>
        <h3>Documents</h3>
        <Col lg={4}>
          <h6>CERTIFICATE OF INCORPORATION</h6>
          <Image src='/Images/Legal-page-doc/certificate-of-incoperation.png' alt='Certificate of Incorporation' width="100%"/>
        </Col>

        <Col lg={4}>
          <h6>GST</h6>
          <Image src='/Images/Legal-page-doc/gst-01.png' alt='GST Document' width="100%"/>
        </Col>

        <Col lg={4}>
          <h6>PAN CARD</h6>
          <Image src='/Images/Legal-page-doc/pancard-01.png' alt='PAN Card' width="100%"/>
        </Col>
    
  
      </Row>
    

     
    
   </Container>
  )
}

export default Legal