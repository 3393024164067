export const ConsumerData = [
    
    {   
        id : "1",
        name : "Raghav Raut",
        consumerID: "53485745678",
        amount: "Rs. 50000"
       
        
        

    },
    {   
        id : "2",
        name : "Anjali",
        consumerID: "53485745678",
        amount: "Rs. 50000"
        

    },
    {   
        id : "3",
        name : "Manisha",
        consumerID: "53485745678",
        amount: "Rs. 50000"
       

    },
    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },

    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },

    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },

    {   
        id : "4",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "13",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "13",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "13",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
    {   
        id : "13",
        name : "Shruti",
        consumerID: "53485745678",
        amount: "Rs. 50000"
    },
]
   


