import { useState } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

import { NavLink } from 'react-router-dom';
import Delete from '../support-page-data/Delete';
import Transfer from '../support-page-data/Transfer';
import Update from '../support-page-data/Update';
import MyHelmet from "./Helmet";

function Support() {
 const [toggle, settoggle] = useState(1)

 const toggleTab =(index) =>{
    settoggle(index)
 }

 return (
   <>
     <MyHelmet
       title="Support"
       description="Welcome to my site! Explore our products and more."
       canonicalUrl="http://mysite.com/example"
     />
     <Container>
       <Col className='mt-5'>
         <Card  className='mx-auto '>
           <Card.Body>
             <Row>
               <Nav variant="pills tab" className='flex-column flex-md-row text-center justify-content-center'>
                 <Col xs={12} md={4} lg={4} className='mb-3 ps-3 '>
                   <NavLink className="nav-link" onClick={() => toggleTab(1)}>
                     <span>Transfer Consumer Request</span>
                   </NavLink>
                 </Col>
                 <Col xs={12} md={4} lg={4} className='mb-3  ps-3'>
                   <NavLink className="nav-link" onClick={() => toggleTab(2)}>
                     Update Consumer Request
                   </NavLink>
                 </Col>
                 <Col xs={12} md={4} lg={4} className='mb-3 ps-3 '>
                   <NavLink className="nav-link" onClick={() => toggleTab(3)}>
                     Delete Consumer Request
                   </NavLink>
                 </Col>
               </Nav>
             </Row>

             {toggle === 1 && <Transfer />}
             {toggle === 2 && <Update />}
             {toggle === 3 && <Delete />}
           </Card.Body>
         </Card>
       </Col>
     </Container>
   </>
 );
}

export default Support;
