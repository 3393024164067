import { useState } from "react";
import { Button, Card, CardTitle, Col, Container, Form } from "react-bootstrap";

function Register() {
    const data = { username: "", number: "", password: "" }
    const [usedata, setusedata] = useState(data)

    
    const [formErrors,setformErrors] = useState({})
    const [isSubmit,setisSubmit] = useState(false)

    const handleData = (e) => {
        setusedata({ ...usedata, [e.target.name]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setformErrors(validate(usedata));
        setisSubmit(true);

        // try {
        //     axios.post('https://jsonplaceholder.typicode.com/posts', usedata)
        //         .then((response) => {
        //             console.log(response);
        //             alert("Successfully Registered")
        //         });
        // } catch (error) {
        //     console.error(error);
        // }
    }

    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
        if (!values.username) {
          errors.username = "Name is required";
        }

        if (!values.number ) {
            errors.number = "Phone Number is required";
          }
        
          if (!values < 10) {
            errors.number = "10 digit number is required";
          }
      
        if (!values.password) {
          errors.password = "Password is required";
        } 
      
      
        return errors;
      };
      

    return (
        <Container className="">
            <Col className='receiver text-center mt-5 text-uppercase'><h2>Register</h2></Col>
            <Col className="mt-5">
                <Card className="rounded col-lg-5 mx-auto">
                    <Card.Body>
                        <CardTitle className="text-muted text-center  mb-4">Sign Up</CardTitle>

                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3 me-3 ms-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="text" placeholder="Consumer Name" name="username" value={usedata.username} onChange={handleData} />
                                <p className='text-danger'>{formErrors.username}</p>
                            </Form.Group>

                            <Form.Group className="mb-3 me-3 ms-3" controlId="exampleForm.ControlInput2">
                                <Form.Control type="consumernumber" placeholder="Consumer Number" name="number" value={usedata.number} onChange={handleData} />
                                <p className='text-danger'>{formErrors.number}</p>
                            </Form.Group>

                            <Form.Group className="mb-3 me-3 ms-3" controlId="exampleForm.ControlInput3">
                                <Form.Control type="password" placeholder="Password" name="password" value={usedata.password} onChange={handleData} />
                                <p className='text-danger'>{formErrors.password}</p>
                            </Form.Group>

                            <Form.Check className='ms-3 mb-2' label="Remember me" />

                            <Col lg={12} className="text-center">
                                <Button type="submit">Sign Up</Button>
                            </Col>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Container>
    )
}

export default Register;
