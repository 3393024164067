import React from 'react';

function Footer() {
  return (
  <>
   <b><hr className='text-white'/></b>
   <div className='footer  '>
   <footer id="footer" className="footer " >
       <div className='container-fluid p-4 text-center '>
          <div className='copyright'>
             Privacy and Policy<strong><br/><span>©2023 Giventake</span></strong>
          </div>
        </div>
      </footer>
      </div>
    </>
  )
}

export default Footer;