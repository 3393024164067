export const FAQdatalist1 =[
    {
        question : "1. What is Compnay Name ?",
        Answer :   "a) Prasanth Panachikkal Enterprises Private Limited"
    },
    {
        question : "2. What is Compnay Corporate Identity Number (CIN) and Registered Number ?",
        Answer :   "a) CIN: U72200KL2016PTC046962, Registration Number: 046962"
    },
    {
        question : "1. What is Permanant Account Number (PAN Crad No) of Company ?",
        Answer :   "a)  AAICP8972D"
    },
    {
        question : "1. When was the company founded?",
        Answer :   "a) Prasanth panachikkal marketing private limited was registered on 05/10/2016. On 01/12/2021 it was renamed PRASANTH PANACHIKKAL ENTERPRISES PVT LTD"
    },
    {
        question : "1. When was the platform founded ?",
        Answer :   "a) On 26-12-2018 giveNtake.world online helping platform was pre-launched as the first ever project of PRASANTH PANACHIKKAL ENTERPRISES PVT LTD. It was later soft launched on 24-02-2020."
    }

]

export const FAQdatalist2 =[
    {
        question : "21. How can the consumer understand the amount of financial help they received on their own ?",
        Answer :   " a) When customers log in to the giveNtake.world online helping platform, they can  view and download the exact amount of financial help from the transaction history  on the menu bar."
    },


]