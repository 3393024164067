import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import MyHelmet from './Helmet';


function Contact() {

  const initialvalues = {name: "", email: "", phone: "", verify:""}
  const [formvalues,setformvalues] = useState (initialvalues)
  const [formErrors,setformErrors] = useState({})
  const [isSubmit,setisSubmit] = useState(false)
  const [successMessage, setSuccessMessage] = useState("");

  const randomString = Math.random().toString(36).slice(8)
  const [captcha,setCaptcha] = useState(randomString)

  const handleChange = (e) => {
    //  setformvalues({...formvalues, [e.target.name]: e.target.value })
    setformvalues(prevValues => ({...prevValues, [e.target.name]: e.target.value }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setformErrors(validate(formvalues));
    setisSubmit(true);

    if (Object.keys(formErrors).length === 0) {
      
      setSuccessMessage("Form submitted successfully!");
    }
  };
    // try {
    //     axios.post('https://jsonplaceholder.typicode.com/posts', formvalues)
    //         .then((response) => {
    //             console.log(response);
    //             alert("Successfully Registered")
    //         });
    // } catch (error) {
    //     console.error(error);
    // }

useEffect(() => {
  
  console.log(formvalues);
}, []);


const validate = (values) => {
  const errors = {};
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!values.name) {
    errors.name = "Name is required";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!regex.test(values.email)) {
    errors.email = "Invalid email format";
  }

  if (!values.phone ) {
    errors.phone = "Phone Number is required";
  }

  if (!values < 10) {
    errors.phone = "10 digit number is required";
  }

  if (!values.verify) {
    errors.verify = "Captcha is required";
  }

 
  return errors;
};


return (
   <>
    <MyHelmet
        title="Contact"
        description="Welcome to my site! Explore our products and more."
        canonicalUrl="http://mysite.com/example"
    />

      <Container>
        <Row>
          <Col className=' receiver contact text-center text-uppercase mt-5 mb-5 '>
              <h1>Contact</h1>
          </Col>
          
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30278.946891658783!2d73.84208028755442!3d18.444285100029063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eac101655f87%3A0xcc5b0a20d60c9ac8!2sKatraj%2C%20Pune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1705053201800!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>    
          
          <div className=" paragraph mt-5 col-lg-6 text-uppercase">
            <h1 className="text-capitalize">Office address</h1>
            <h6 className="mt-5 mb-3">PRASANTH PANACHIKKAL ENTERPRISES PVT LTD</h6>
            <h6 >FIRST FLOOR, 23/326/53-1, N P TOWER<br/>
                WEST FORT, PIN - 680004<br/>
                THRISSUR, KERALA</h6>

          <span><NavLink className="nav-link mt-4 text-lowercase"><FontAwesomeIcon icon={faEnvelope} /> info@giventake.world</NavLink></span>
          <span><i className=" mdi mdi-email"></i><NavLink className="nav-link text-lowercase"><FontAwesomeIcon icon={faPhone} /> +91 9846 073366</NavLink></span>

          </div>
        

          <Form className="paragraph form mt-5 col-lg-6 " onSubmit={handleSubmit}>
            <h1 className="text-capitalize mb-2">React Us</h1>

          <Form.Group  controlId="validationCustom01"> 
            <Form.Label className="name " > Name <span className="text-danger">*</span></Form.Label><br/>
            <Form.Control type="text" name="name" value={formvalues.name} onChange={handleChange}/>
            <p className='text-danger'>{formErrors.name}</p>
          </Form.Group>
            
          <Form.Group  controlId="validationCustom02"> 
            <Form.Label className="email mt-2" > Email <span className="text-danger">*</span></Form.Label><br/>
            <Form.Control  type="email" name="email" value={formvalues.email} onChange={handleChange}/>
            <p className='text-danger'>{formErrors.email}</p>
          </Form.Group>
            
          <Form.Group  controlId="validationCustom03"> 
            <Form.Label className="phone mt-2" > Phone <span className="text-danger">*</span></Form.Label><br/>
            <Form.Control  type="phonenumber" name="phone" value={formvalues.phone} onChange={handleChange}/>
            <p className='text-danger'>{formErrors.phone}</p>
          </Form.Group>

          <Form.Group  controlId="validationCustom04"> 
            <Form.Label className="message mt-2" > Message <span className="text-danger">*</span></Form.Label><br/>
            <Form.Control as="textarea" style={{ height: '100px' }} name="message" value={formvalues.message} onChange={handleChange}/>
          </Form.Group>

       <Row>
          <Form.Group  controlId="formGridCity">
            <Col >
            <input  type="text" name="verify" value={formvalues.verify} placeholder="Captcha Code" className="form-control  mt-3 " onChange={handleChange} />
            <p className='text-danger'>{formErrors.verify}</p>
            </Col>
            <Col>
            <small >Enter below captcha</small><br></br>
            <img src="https://www.rfaquasolutions.com/captcha" alt="verification" class="img-fluid" />
            </Col>
          </Form.Group>
        </Row>

       {/* <Row className='mt-3'>
        <Captcha/>
       </Row> */}

          <Col className='text-end mt-3 '>
            <Button className=' bg-warning' type='submit'>Submit</Button>
          </Col>

    
      </Form>

    
{/* 
      {successMessage && (
          <Col className='text-center mt-3 text-white'>
            <p>{successMessage}</p>
          </Col>
        )} */}
    </Row>
  </Container>
</>
)
}
export default Contact