import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

function KYC() {

  const [formErrors,setformErrors] = useState({});

  const [isEditMode, setIsEditMode] = useState(false);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };


  const [formData1, setFormData1]= useState({
    familyName: '',
    fatherName: '',
    motherName: '',
    WifeHusbandName: '',
    children: '',
    education: '',
    job: '',
    monthlyincome: '',
    yearlyincome: '',
    house: '',
    liabilities: '',
    cibilscore: '',
    message: '',

  })

  const [formData2,setFormData2] = useState({
    accountholdername: '',
    bankaccountnumber: '',
    ifsccode: '',
    bankname: '',
    bankbranch: '',
    nomineename: '',
    nomineecontactnumber: '',
    nomineerelationship: '',
    nomineeAadhaarFront: null,
    nomineeAadhaarBack: null,
    adharcardnumber: '',

  })
  const [formData3, setFormData3] = useState({
  
    panNumber: '',
    panCardFront: null,
    passportNumber: '',
    passportFront: null,
    passportBack: null,
    rationCardNumber: '',
    rationCardFront: null,
    rationCardBack: null,
    drivingLicense: '',
    drivingLicenseFront: null,
    drivingLicenseBack: null,
   
 
    // Add more fields as needed
  });



  const handleInputChange1 = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      setFormData1((prevData) => ({
        ...prevData,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData1((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  
  const handleInputChange2 = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      setFormData2((prevData) => ({
        ...prevData,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData2((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  
  const handleInputChange3 = (e) => {
    const { name, value, type } = e.target;
    if (type === 'file') {
      setFormData3((prevData) => ({
        ...prevData,
        [name]: e.target.files[0],
      }));
    } else {
      setFormData3((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    // setformErrors(validate(formData1));

    try {
      const postData = new FormData();
      for (const key in formData1) {
        if (formData1[key] !== null) {
          postData.append(key, formData1[key]);
        }
      }

      // Replace 'http://localhost:3001/api/kyc' with your actual API endpoint
      await axios.post('https://jsonplaceholder.typicode.com/posts', postData);
      console.log('KYC data saved successfully');
    } catch (error) {
      console.error('Error saving KYC data:', error);
    }
  };

  const handleSave1 = async (e) => {
    e.preventDefault();
    setformErrors(validate(formData2));

    try {
      const postData = new FormData();
      for (const key in formData2) {
        if (formData2[key] !== null) {
          postData.append(key, formData2[key]);
        }
      }

      // Replace 'http://localhost:3001/api/kyc' with your actual API endpoint
      await axios.post('https://jsonplaceholder.typicode.com/posts', postData);
      console.log('KYC data saved successfully');
    } catch (error) {
      console.error('Error saving KYC data:', error);
    }
  };

  const handleSave2 = async (e) => {
    e.preventDefault();
    // setformErrors(validate(formData3));

    try {
      const postData = new FormData();
      for (const key in formData3) {
        if (formData3[key] !== null) {
          postData.append(key, formData3[key]);
        }
      }

      // Replace 'http://localhost:3001/api/kyc' with your actual API endpoint
      await axios.post('https://jsonplaceholder.typicode.com/posts', postData);
      console.log('KYC data saved successfully');
    } catch (error) {
      console.error('Error saving KYC data:', error);
    }
  };


  
  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!values.accountholdername) {
      errors.accountholdername = "Account Holder Name is required";
    }

    if (!values.bankaccountnumber ) {
        errors.bankaccountnumber = "Account Number is required ";
      }
    
    if (!values.ifsccode) {
      errors.ifsccode = "IFSC code is required";
    }
  
    if (!values.bankname) {
      errors.bankname = "Bank Name is required";
    } 

    
    if (!values.bankbranch) {
      errors.bankbranch = "Bank branch name is required";
    } 

    if (!values.nomineename) {
      errors.nomineename = "Nominee Name is required";
    } 
  
    if (!values.nomineecontactnumber) {
      errors.nomineecontactnumber = "Contact Number is required";
    } 

    if (!values.nomineerelationship) {
      errors.nomineerelationship = "Nominee Relationship is required";
    } 

    if (!values.nomineeAadhaarFront) {
      errors.nomineeAadhaarFront = "Nominee Adhar Card is required";
    } 

    if (!values.nomineeaadhaarback) {
      errors.nomineeaadhaarback = "Nominee Adhar Card is required";
    }
    
    if (!values.adharcardnumber) {
      errors.adharcardnumber = "Nominee Adhar Number is required";
    } 

  
    return errors;
  };

  return (

    <Container>

      <Col className='receiver contact text-center text-uppercase mt-5 mb-5'>
        <h1>KYC</h1>
      </Col>

      <Form className="text-white">

        <h3 className=" mb-2 text-white">Family Details</h3>

        <Form.Group className='col-lg-6' controlId="validationCustom01">
          <Form.Label className="name " >Family Name</Form.Label><br />
          <Form.Control required type="text" name="familyName"
            value={formData1.familyName}
            onChange={handleInputChange1} />
        </Form.Group>

        <Row className='mt-4 mb-4'>
          <Col >
            <Form.Group controlId="validationCustom02">
              <Form.Label className="name " >Father Name</Form.Label><br />
              <Form.Control required type="text" name="fatherName"
                value={formData1.fatherName}
                onChange={handleInputChange1} />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="validationCustom03">
              <Form.Label className="name " >Mother Name</Form.Label><br />
              <Form.Control required type="text" name="motherName"
                value={formData1.motherName}
                onChange={handleInputChange1} />
            </Form.Group>
          </Col>

        </Row>


        <h6>Maritial Status</h6>

        <Col className='d-flex'>
          <Form.Check className='me-4' type="radio" label="Married" />
          <Form.Check type="radio" label="Unmarried" />
        </Col>

        <Row className="mt-4">

          <Col >
            <Form.Group controlId="validationCustom04">
              <Form.Label className="name " >Wife/Husband Name</Form.Label>
              <Form.Control required type="text" name="WifeHusbandName"
                value={formData1.WifeHusbandName}
                onChange={handleInputChange1} />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="validationCustom05">
              <Form.Label className="name " >Children</Form.Label>
              <Form.Control required type="text" name="children"
                value={formData1.children}
                onChange={handleInputChange1} />
            </Form.Group>
          </Col>

        </Row>

        <Row className="mt-4">

          <Col>
            <Form.Group controlId="validationCustom06">
              <Form.Label className="name " >Education</Form.Label>
              <Form.Control required type="text" name="education"
                value={formData1.education}
                onChange={handleInputChange1} />
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="validationCustom07">
              <Form.Label className="name " >Job</Form.Label>
              <Form.Control required type="text" name="job"
                value={formData1.job}
                onChange={handleInputChange1} />
            </Form.Group>
          </Col>
          </Row>

          <Row className="mt-4">
          
            <Col >
              <Form.Group controlId="validationCustom08">
                <Form.Label>Monthly Family Income</Form.Label>
                <Form.Control required type="text" name="monthlyincome"
                  value={formData1.monthlyincome}
                  onChange={handleInputChange1} />
              </Form.Group>
            </Col>

            <Col >
              <Form.Group controlId="validationCustom09">
                <Form.Label>Yearly Family Income</Form.Label>
                <Form.Control required type="text" name="yearlyincome"
                  value={formData1.yearlyincome}
                  onChange={handleInputChange1} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="validationCustom10">
                <Form.Label>House</Form.Label>
                <Form.Control required type="text" name="house"
                  value={formData1.house}
                  onChange={handleInputChange1} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="validationCustom11">
                <Form.Label  >Liabilities in Rs.</Form.Label>
                <Form.Control required type="text" name="liabilities"
                  value={formData1.liabilities}
                  onChange={handleInputChange1} />
              </Form.Group>
            </Col>

          </Row>

          <Row className="mt-4">

            <Col >
              <Form.Group controlId="validationCustom12">
                <Form.Label className="name ">Cibil Score.</Form.Label><br />
                <Form.Control required type="text" name="cibilscore"
                  value={formData1.cibilscore}
                  onChange={handleInputChange1} />
              </Form.Group>
            </Col>

            <Col className=' mt-4'>
              <Form.Label className="name ">Owned Vehicles</Form.Label>
              <Col className="d-flex">
                <Form.Check className='me-4' type="radio" label="Motorcycle" />
                <Form.Check type="radio" label="Car" />
              </Col>
            </Col>

            <Col className=' mt-4'>
              <Form.Label className="name ">Insurance Details</Form.Label>
              <Col className="d-flex">
                <Form.Check className='me-4' type="radio" label="Medical" />
                <Form.Check type="radio" label="Term" />
              </Col>
            </Col>

        
        </Row>

        <Col className="mt-4"><h6>Do you file income tax?</h6>
          <Col className="d-flex">
            <Form.Check className='me-4' type="radio" label="Yes" />
            <Form.Check type="radio" label="No" />
          </Col>
        </Col>

        <Col className="mt-4"><h6>Do anyone in family have any health problems ?</h6>
          <Col className="d-flex">
            <Form.Check className='me-4' type="radio" label="Yes" />
            <Form.Check type="radio" label="No" />
          </Col>
        </Col>

        <Col lg={9} className="mt-3">
          <Form.Group controlId="validationCustom13">
            <Form.Control as="textarea" style={{ height: '100px' }} name="message"
              value={formData1.message}
              onChange={handleInputChange1} />
          </Form.Group>
        </Col>

        <Col className='d-flex justify-content-end mt-4 '>
          <Button className='border me-2' onClick={toggleEditMode}>Edit</Button>
          <Button className='bg-warning' onClick={handleSave}>Save</Button>
        </Col>


        <h5 className='mt-4'>Bank Details</h5>
        <Row className="mt-4">

          <Col>
            <Form.Group controlId="validationCustom14">
              <Form.Label className="name " >Account Holder Name<span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="accountholdername"
                value={formData2.accountholdername}
                onChange={handleInputChange2} />
                <p className='text-danger '>{formErrors.accountholdername}</p>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="validationCustom15">
              <Form.Label className="name " >Bank Account Number<span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="bankaccountnumber"
                value={formData2.bankaccountnumber}
                onChange={handleInputChange2} />
                <p className='text-danger '>{formErrors.bankaccountnumber}</p>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="validationCustom16">
              <Form.Label className="name " >IFSC Code<span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="ifsccode"
                value={formData2.ifsccode}
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.ifsccode}</p>
            </Form.Group>
          </Col>

        </Row>

        <Row className="mt-4">

          <Col>
            <Form.Group controlId="validationCustom17">
              <Form.Label className="name " >Bank Name <span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="bankname"
                value={formData2.bankname}
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.bankname}</p>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="validationCustom18">
              <Form.Label className="name " >Bank Branch <span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="bankbranch"
                value={formData2.bankbranch}
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.bankbranch}</p>
            </Form.Group>
          </Col>

        </Row>

        <h5 className="mt-4">Nominee Details</h5>
        <Row className='mt-4'>

          <Col>
            <Form.Group controlId="validationCustom19">
              <Form.Label className="name " >Nominee Name<span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="nomineename"
                value={formData2.nomineename}
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.nomineename}</p>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="validationCustom20">
              <Form.Label className="name " >Nominee Contact Number <span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="nomineecontactnumber"
                value={formData2.nomineecontactnumber}
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.nomineecontactnumber}</p>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="validationCustom21">
              <Form.Label className="name " >Nominee Relationship <span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="nomineerelationship"
                value={formData2.nomineerelationship}
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.nomineerelationship}</p>
            </Form.Group>
          </Col>

        </Row>

        <Row className="mt-4">

          <Col>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className='required'>Nominee Aadhaar Front <span className='asterik'> * </span> </Form.Label>
              <Form.Control type="file" name="nomineeaadhaarfront"
                accept="image/*"
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.nomineeAadhaarFront}</p>
            </Form.Group>

          </Col>

          <Col>
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label className='required'>Nominee Aadhaar Back <span className='asterik'> * </span> </Form.Label>
              <Form.Control type="file" name="nomineeaadhaarback"
                accept="image/*"
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.nomineeaadhaarback}</p>
            </Form.Group>

          </Col>

          <Col>
            <Form.Group controlId="validationCustom22">
              <Form.Label className="name " >Aadhar Card Number<span className='asterik'> * </span></Form.Label><br />
              <Form.Control required type="text" name="adharcardnumber"
                value={formData2.adharcardnumber}
                onChange={handleInputChange2} />
                <p className='text-danger'>{formErrors.adharcardnumber}</p>
            </Form.Group>
          </Col>
          </Row>

          <Col className='d-flex justify-content-end mt-4 '>
            <Button className='border me-2' onClick={toggleEditMode}>Edit</Button>
            <Button className='bg-warning' type='submit'  onClick={handleSave1} >Save</Button>       
      
          </Col>
         
          

          <h4 className='mt-4'>Upload Documents</h4>

          <Col className='d-flex mt-4'>
            <h5 className='me-3'>Pan Card</h5>
            <Form.Check aria-label="option 1" />
          </Col>

          <Row className='mt-4'>

            <Col lg={4}>
              <Form.Group controlId="validationCustom23">
                <Form.Label className="name " >Pan Number</Form.Label><br />
                <Form.Control required type="text" name="panNumber"
                  value={formData3.panNumber}
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

            <Col lg={4}>
              <Form.Group  controlId="formGridState">
                <Form.Label className='required'>Pan card Front ( Current file)</Form.Label>
                <Form.Control type="file" name="panCardFront"
                  accept="image/*"
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

          </Row>

          <Col className='d-flex mt-4'>
            <h5 className='me-3'>Passport</h5>
            <Form.Check aria-label="option 1" />
          </Col>

          <Row className='mt-4'>

            <Col>
              <Form.Group controlId="validationCustom24">
                <Form.Label className="name " >Passport Number</Form.Label><br />
                <Form.Control required type="text" name="passportNumber"
                  value={formData3.passportNumber}
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formGridState">
                <Form.Label className='required'>Passport Front ( Current file)</Form.Label>
                <Form.Control type="file" name="passportFront"
                  accept="image/*"
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formGridState">
                <Form.Label className='required'>Passport Back ( Current file)</Form.Label>
                <Form.Control type="file" name="passportBack"
                  accept="image/*"
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>
          </Row>

          <Col className='d-flex mt-4'>
            <h5 className='me-3'>Ration Card </h5>
            <Form.Check aria-label="option 1" />
          </Col>

          <Row className='mt-4'>

            <Col>
              <Form.Group controlId="validationCustom25">
                <Form.Label className="name " >Ration Card Number</Form.Label><br />
                <Form.Control required type="text" name="rationCardNumber"
                  value={formData3.rationCardNumber}
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label className='required'>Ration card Front ( Current file)</Form.Label>
                <Form.Control type="file" name="rationCardFront"
                  accept="image/*"
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group as={Col} controlId="formGridState">
                <Form.Label className='required'>Ration card Back ( Current file)</Form.Label>
                <Form.Control type="file" name="rationCardBack"
                  accept="image/*"
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>
          </Row>

          <Col className='d-flex mt-4'>
            <h5 className='me-3'>Driving License</h5>
            <Form.Check aria-label="option 1" />
          </Col>

          <Row className='mt-4'>

            <Col>
              <Form.Group controlId="validationCustom26">
                <Form.Label className="name " >Driving License </Form.Label><br />
                <Form.Control required type="text" name="drivingLicense"
                  value={formData3.drivingLicense}
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formGridState">
                <Form.Label className='required'>Driving License Front ( Current file)</Form.Label>
                <Form.Control type="file" name="drivingLicenseFront"
                  accept="image/*"
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group controlId="formGridState">
                <Form.Label className='required'>Driving License Back ( Current file) </Form.Label>
                <Form.Control type="file" name="drivingLicenseBack"
                  accept="image/*"
                  onChange={handleInputChange3} />
              </Form.Group>
            </Col>

          </Row>

          <Col className='d-flex justify-content-end mt-4 '>
            <Button className='border me-2' onClick={toggleEditMode}>Edit</Button>
            <Button className='bg-warning' onClick={handleSave2}>Save</Button>
          </Col>

       
      </Form>
    </Container>
  )
}

export default KYC