import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Container, Form, Pagination, Row, Table } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function Invoice() {
  return (
    <Container>
        <h2 className=" receiver text-uppercase text-center mt-5">Invoice</h2>

        <Col>
         <Row>
            <Form className='d-flex'>
                <Form.Group as={Col} controlId="formGridNumber">
                    <Form.Label className='d-flex col-lg-3 mt-3' > Show
                        <Form.Select  className='ms-1 me-1'>
                            <option value="1">10</option>
                            <option value="2">20</option>
                            <option value="3">25</option>
                        </Form.Select>
                        Entries
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridNumber">
                    <Form.Label className='d-flex  mt-3 offset-lg-8' > Search: <Form.Control type="search" /></Form.Label>
                            
                </Form.Group>
            </Form>
        </Row>
    </Col>

    <Table striped bordered hover variant="dark" className='mt-4 text-end'>
      <thead>
        <tr>
          <th className='sorting'># </th>
          <th>Created at</th>
          <th>Invoice Number</th>
          
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Dec 30-2023-12:44 PM	</td>
          <td>AL/53752/23-24</td>
          <td><NavLink to="/invoice-bill" className="nav-link"><FontAwesomeIcon icon={faEye} /></NavLink></td>
        
        </tr>
        <tr>
          <td>2</td>
          <td>Dec 30-2023-01:14 PM	</td>
          <td>AL/53779/23-24</td>
          <td><NavLink to="/invoice-bill" className="nav-link"><FontAwesomeIcon icon={faEye} /></NavLink></td>
          
        </tr>
     
       
      </tbody>
    </Table>

  <Row className='mt-4'>
    <Col >
      <h6>Showing 1 to 10 of 212 entries2 rows selected</h6>
    </Col>

    <Col >
     <Pagination className='justify-content-end m '>
      <Pagination.First />
      <Pagination.Prev />
      <Pagination.Item>{1}</Pagination.Item>
    

      <Pagination.Next />
      <Pagination.Last />
    </Pagination>

    </Col>
  </Row>
      

    </Container>

  )
}

export default Invoice