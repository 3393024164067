import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";


function MyProfile() {
  return (
    <Container>
      <h2 className=" receiver text-uppercase text-center mt-5">My profile</h2>

      <Row>
        <Col>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>
        <Col className='offset-lg-4 upload-kit text-center' lg={4}>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Passport Size Photo</Form.Label>
            <Form.Label className="btn  " type="file" >
              <FontAwesomeIcon icon={faFileUpload} />
              Browser Upload<br />
              <Image className='border' src="https://gnt-prod.s3.ap-south-1.amazonaws.com/source/aadhaarFront_FOzgbTZ.jpg" width="20%" /></Form.Label>

          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Form.Label>Gender</Form.Label>
          <Form.Select aria-label="Default select example ">
            <option value="1">Male</option>
            <option value="2">Female</option>
            <option value="3">Transgender</option>
          </Form.Select>
        </Col>

        <Col>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control type="date" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Label>Blood Group</Form.Label>
          <Form.Select aria-label="Default select example">
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </Col>
      </Row>

      <Row className="mt-3">

        <Col lg={4}>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>State</Form.Label>
            <Form.Select aria-label="Default select example">
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
        </Col>

        <Col lg={4}>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>District</Form.Label>
            <Form.Select aria-label="Default select example">
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <h2 className="mb-3">Permanant Address</h2>

          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Address Line 1 </Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>

          <Form.Group className="mt-3" as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>

          <Row className="mt-3">
            <Col >
              <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>House No.</Form.Label>
                <Form.Control type="Phone Number" />
              </Form.Group>
            </Col>

            <Col >
              <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Ward No.</Form.Label>
                <Form.Control type="Phone Number" />
              </Form.Group>
            </Col>

            <Col >
              <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Postal Code</Form.Label>
                <Form.Control type="Phone Number" />
              </Form.Group>
            </Col>
          </Row>

        </Col>

        <Col>
          <h2>Living Address </h2>
          <Form  >
            <Form.Check className="mb-3"
              type="switch"
              id="custom-switch"
              label="Same as permanent address"
            />
            <Form.Group as={Col} controlId="formGridPhoneNumber">
              <Form.Label>Address Line 1 </Form.Label>
              <Form.Control type="Phone Number" />
            </Form.Group>

            <Form.Group className="mt-3" as={Col} controlId="formGridPhoneNumber">
              <Form.Label>Address Line 2</Form.Label>
              <Form.Control type="Phone Number" />
            </Form.Group>
          </Form>

          <Row className="mt-3">
            <Col >
              <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>House No.</Form.Label>
                <Form.Control type="Phone Number" />
              </Form.Group>
            </Col>

            <Col >
              <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Ward No.</Form.Label>
                <Form.Control type="Phone Number" />
              </Form.Group>
            </Col>

            <Col >
              <Form.Group as={Col} controlId="formGridPhoneNumber">
                <Form.Label>Postal code.</Form.Label>
                <Form.Control type="Phone Number" />
              </Form.Group>
            </Col>

          </Row>
        </Col>
      </Row>

      {/* <Row>
        <Col className="mt-5 ">
          <h2>Social Media Contacts</h2>
          <Form>
          <InputForm Label="Whatsapp Number"type = "number"/>
          </Form>
        </Col>
      </Row> */}
      <h2 className="mt-3">Social Media Contacts</h2>
      <Row className="mt-5">

        <Col >
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Whatsapp Number</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Telegram Number</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Facebook ID</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>
      </Row>


      <h2 className="mt-5">UPI Details</h2>

      <Row className="mt-3">

        <Col >
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Bank UPI ID</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>BHIM UPI</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>GPay</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mt-3">

        <Col >
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>PhonePe</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Paytm</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>

        <Col >

          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Whatsapp Pay</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>
      </Row>

      <h2 className="mt-5">UPI Details</h2>


      <Row className="mt-3">

        <Col >
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Adhar Number</Form.Label>
            <Form.Control type="Phone Number" />
          </Form.Group>
        </Col>

        <Col>
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Adhar Card Front </Form.Label>
            <Form.Label className="btn border " type="file" >
              <FontAwesomeIcon icon={faFileUpload} />
              Browser Upload<br />
              <Image src="https://gnt-prod.s3.ap-south-1.amazonaws.com/source/aadhaarFront_FOzgbTZ.jpg" width="30%" /></Form.Label>

          </Form.Group>
        </Col>

        <Col >
          <Form.Group as={Col} controlId="formGridPhoneNumber">
            <Form.Label>Adhar Card Back </Form.Label>
            <Form.Label className="btn border " type="file" >
              <FontAwesomeIcon icon={faFileUpload} />
              Browser Upload<br />
              <Image src="https://gnt-prod.s3.ap-south-1.amazonaws.com/source/aadhaarBack_5Prceg0.jpg" width="52%" /></Form.Label>
          </Form.Group>
        </Col>
      </Row>

      <Col className='text-end'>
        <Button>Save</Button>
      </Col>

 </Container>
  )
}

export default MyProfile
