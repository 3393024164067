export const  selectStates =[
        {
          state: "Andhra Pradesh" , 
        },
        {
          state: "Arunachal-Pradesh", 
        },
        {
          state: "Aasam"  
        },
        {
          state: "Bihar" 
        },
        {
          state : "Chattisgarh" 
        },
        {
          state : "Goa" 
        },
        {
          state : "Hariyana"
        },
        {
          state : "Himachal Pradesh"  
        },
        {
          state : "Jammu and Kashmir"  
        },
        {
          state : "Jharkhand"
        },
        {
        state : "Karnataka" 
        },
        {
          state : "Kerela" 
        },
        {
          state : "Madhyapradesh" 
        },
        {
          state : "Maharashtra" 
        },
        {
          state : "Manipur"  
        },
        {
          state :"Meghalaya"
        },
        {
          state : "Mizoram"
        },
        {
          state : "Nagaland"
        },
        {
          state : "Odisa"
        },
        {
          state : "Punjab"
        },
        {
          state : "Rajasthan"
        },
        {
          state : "Sikkim"
        },
        {
          state : "Tamilnadu"
        },
        {
          state : "Telangana"
        },
        {
          state : "Tripura"
        },
        {
          state : "Uttar Pradesh",
        },
        {
          state : "Uttrakhand"
        },  {
          state : "West Bengal",
        },  
        {
          state : "Andaman and Nikobar",
        },
        {
          state : "Chandigarh",
        },
        {
          state : "Dadra and Nagar Haveli" ,
        },
        {
          state : "Daman and Diu",
        },
        {
          state : "Lakshadweep",
        },
        {
          state : "Delhi",
        },
        {
          state : "Puducherry",
        },
        {
          state : "Gujrat",
        },
        {
          state : "Laddakh",
        },
     ]

        // District Selection

     export const  selectDistrict =[
        {
          district: "Andhra Pradesh" , 
        },
        {
          district: "Arunachal-Pradesh", 
        },
        {
          district: "Aasam"  
        },
        {
          district: "Bihar" 
        },
        {
          district : "Chattisgarh" 
        },
        {
          district : "Goa" 
        },
        {
          district : "Hariyana"
        },
        {
          district : "Himachal Pradesh"  
        },
        {
          district : "Jammu and Kashmir"  
        },
        {
          district : "Jharkhand"
        },
        {
          district : "Karnataka" 
        },
        {
          district: "Kerela" 
        },
        {
          district : "Madhyapradesh" 
        },
        {
          district : "Maharashtra" 
        },
        {
          district : "Manipur"  
        },
        {
          district :"Meghalaya"
        },
        {
          district : "Mizoram"
        },
        {
          district : "Nagaland"
        },
        {
          district : "Odisa"
        },
        {
          district : "Punjab"
        },
        {
          district : "Rajasthan"
        },
        {
          district : "Sikkim"
        },
        {
          district : "Tamilnadu"
        },
        {
          district : "Telangana"
        },
        {
          district : "Tripura"
        },
        {
          district : "Uttar Pradesh",
        },
        {
          district : "Uttrakhand"
        },  
        {
          district : "West Bengal",
        },
        {  
          district : "Andaman and Nikobar",
        },
        {
          district: "Chandigarh",
        },
        {
          district : "Dadra and Nagar Haveli" ,
        },
        {
          district : "Daman and Diu",
        },
        {
          district : "Lakshadweep",
        },
        {
          district : "Delhi",
        },
        {
          district: "Puducherry",
        },
        {
          district : "Gujrat",
        },
        {
          district : "Laddakh",
        },
     ]

    

