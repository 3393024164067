import { Col, Container, Form } from 'react-bootstrap';
import DynamicTable from '../dynamic_table/Dynamic-Table';
import MyHelmet from './Helmet';
import { selectDistrict, selectStates } from './State-and-District-Selection';

function Home() {

  return (
    <>
        <MyHelmet
            title="Top Receivers"
            description="Welcome to my site! Explore our products and more."
            canonicalUrl="http://mysite.com/example"
        />
   
      
   <Container>
        <Col  className="offset-4 " >
          <img src='/Images/home-logo/give-and-take-logo03.png' alt='logo' width="40%" />
        </Col>
  

        <div className='offset-3 form d-flex me-5 pe-5 mt-4 '>
          <div className='me-3'>
            <Form.Select aria-label="Default select example" className="form-control choose_state" data-toggle="select2">
              <option value="" className='text-center text-muted' role='textbox'>Select State</option>
              {selectStates.map((item, index) => (
                <option key={index} className='states'>{item.state}</option>
              ))}
            </Form.Select>
          </div>

          <div className='me-3'>
            <Form.Select aria-label="Default select example text-muted font-weight-bold">
              <option value="" className='text-center'>Select District</option>
              {selectDistrict.map((item, index) => (
                <option key={index} className='district'>{item.district}</option>
              ))}
            </Form.Select>
          </div>

          <div className='button rounded'>
            <button type="button" className="btn btn-warning rounded">Search</button>
          </div>
        </div>

        <div className='receiver text-center mt-5 text-uppercase '>
          <h2>Top Receivers</h2>
        </div>

        <div className='row '>
          <div className='offset-lg-1 col-lg-10'>
            <div className=' table-bg"'>
              <div className='table-responsive mt-3 me-5 pe-5 '>
                <DynamicTable />
              </div>

            </div>

          </div>

        </div>

      </Container>
    </>
  )
}

export default Home