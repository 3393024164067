import { Container, Table } from "react-bootstrap"

function GiveHelp() {
  return (
    <Container>
    <h2 className=" receiver text-uppercase text-center mt-5">Give Help</h2>

    <Table striped bordered hover variant="dark" className='mt-4 text-center'>
      <thead>
        <tr>
          <th className='sorting'>Stage</th>
          <th>Consumer No.</th>
          <th>Consumer Name</th>
          <th>Designation</th>
          <th>Mobile</th>
          <th>Give Help Amount</th>
          <th>Status</th>
          <th>Details</th>
          <th>Payment Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>130122023115446</td>
          <td>Naresh pandit patil</td>
          <td>Giventake Associate</td>
          <td>+919561894151</td>
          <td>150</td>
          <td>Paid</td>
          <td></td>
          <td></td>

        
        </tr>
        <tr>
          <td>2</td>
          <td>129122023122642</td>
          <td>sitaram damodar jadhav</td>
          <td>Giventake Associate</td>
          <td>+918554851232</td>
          <td>200</td>
          <td>Paid</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>2</td>
          <td>129122023122642</td>
          <td>Archana Arun Folane</td>
          <td>Giventake Associate</td>
          <td>+918554851232</td>
          <td>200</td>
          <td>Paid</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>2</td>
          <td>129122023122642</td>
          <td>ARUN Lakshman Folane	</td>
          <td>Giventake Associate</td>
          <td>+918554851232</td>
          <td>200</td>
          <td>Paid</td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>2</td>
          <td>129122023122642</td>
          <td>Prakash Shivsing Parihar</td>
          <td>Giventake Associate</td>
          <td>+918554851232</td>
          <td>200</td>
          <td>Paid</td>
          <td>
            {/* <Table variant="dark">
                <tbody>
                    <tr>
                        <td>HEllo</td>
                        
                    </tr>
                    <tr>
                        <td>HEllo</td>
                    </tr>
                    <tr>
                        <td>HEllo</td>
                    </tr>
                </tbody>
            </Table> */}
          </td>
          <td></td>
        </tr>
       
      </tbody>
    </Table>

    </Container>
  )
}

export default GiveHelp