import { Container } from "react-bootstrap"
import MyHelmet from "./Helmet"

function About(){
    return(
   <>
     <MyHelmet
        title="About"
        description="Welcome to my site! Explore our products and more."
        canonicalUrl="http://mysite.com/example"
     />
      <Container>
      <div className='receiver text-center mt-5 text-uppercase '>
          <h2>about us</h2>
        </div>
            
            <div className=" paragraph m-5 ps-5 ">
                
                <div >
                <p>Giventake.world is an innovative business project of Prasanth Panachikkal Enterprises
                     Private Limited. Even though we are beginners, this is a new and humble beginning 
                     to being a part of great history.</p>
                </div>
                <div className=" mb-4">
                    <h1>OUR VISION</h1>
                </div>
                <div>
                    <p>To create a social system that values people and humanity over money.</p>
                </div>
                <div className=" mb-4">
                    <h1>OUR MISSION</h1>
                </div>
                <div>
                    <p>To create the world's first online helping platform where individuals can directly 
                        provide and receive financial assistance for personal needs, and thus to build a
                         community of people with humanity who work in a spirit of mutual help and cooperation.</p>
                </div>
            </div>
            </Container>
       
        </>
    )
}

export default About