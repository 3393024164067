import MyHelmet from "./Helmet";

function TermsandConditions() {
  return (
    <>
        <MyHelmet
            title="Privacy and Policy"
            description="Welcome to my site! Explore our products and more."
            canonicalUrl="http://mysite.com/example"
        />
    
   
    <div className='container mt-5 '>
      <div className='row'>
        <div className='receiver text-center'>
          <h3>TERMS AND CONDITIONS FOR CONSUMERS</h3>
           
        </div>
        <div className=' paragraph  mt-4'>
          <ol>
            <li className='mb-3'> Cooperate or register with giveNtake.world online helping platform with your full consent only after reading each and every Terms and Conditions of giveNtake.world online helping platform very carefully, persuading yourself that you understand and agree.</li>
            <li> I agree to be solely responsible for any financial, social, psychological, or legal harm caused to me in the future as a result of my lack of personal knowledge about the Terms and Conditions of the giveNtake.world online helping platform, or as a result of having associated with the giveNtake.world online helping platform by being intentionally or unintentionally misled by another consumer. I am self-convinced and understand that giveNtake.world online helping platform or Prasanth Panachikkal Enterprises Private Limited is not responsible in any way, and it is 100% my personal need and responsibility as a consumer to fully read and understand the following Terms and Conditions.
                  </li>
          </ol>
        </div>


      </div> 
    </div> 
    </>
  )
}

export default TermsandConditions;