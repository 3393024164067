import { Col, Container, FloatingLabel, FormGroup, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Transfer() {


  return (
    <Container className='mt-4'>
        <Row>
            <Col>
                        
               <Form >
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridConsumerNo">
                        <Form.Label  className='required' >Existing Consumer No<span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="number"  />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridConsumerNo">
                        <Form.Label className='required'>New Consumer No <span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="number" />
                    </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridName">
                        <Form.Label className='required'>Existing Consumer Name <span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="name"  />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPhoneNo">
                        <Form.Label className='required'>New Consumer Phone No <span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="phone no"  />
                    </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridPhoneNo">
                        <Form.Label className='required'>Existing Consumer Phone No <span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="phone no"  />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label className='required'>New Consumer Email ID <span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="email"/>
                    </Form.Group>
                    </Row>
                    
                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Payment Transaction Date </Form.Label>
                        <Form.Control type="date" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridDate">
                        <Form.Label className='required'>New Consumer Address <span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="address"  />
                    </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label className='required'>Payment Transaction ID <span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="paymentid"  />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridState">
                        <Form.Label className='required'>Choose Image <span className='asterik'> * </span> </Form.Label>
                        <Form.Control type="file"  />

                        <p className='text-danger mt-3'>Processing fee Rs. 500.The payment you paid for the process do not refundable.Please pay 9846073366gntadmin@ibl
                            After making your payment attach the transaction screenshot here
                            UPI:9846073366gntadmin@ibl</p>
                    </Form.Group>
                    </Row>

                    <FormGroup>
                    <Form.Label>Message</Form.Label> 
                    <FloatingLabel controlId="floatingTextarea2" >
                    <Form.Control
                        as="textarea"
                        placeholder="Leave a message here"
                        style={{ height: '100px' }}
                    />
                    </FloatingLabel>
                    </FormGroup>

                    <Form.Group className="mb-3 mt-2" id="formGridCheckbox">
                    <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                </Form>

            </Col>
        </Row>
    </Container>
  )
}

export default Transfer