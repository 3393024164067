import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { ConsumerData } from '../components/ConsumerData';

function DynamicTable() {
   
    const [user, setUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [displayCount, setDisplayCount] = useState(5);

    const fetchData = async () => {
        try {
            const response = await axios.get(ConsumerData);
            setUser(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

 
    useEffect(() => {
        // Load initial data from ConsumerData
        setUser(ConsumerData.slice(5, setDisplayCount));
        setLoading(false);


    }, [displayCount]);

  
    const handleLoadMore = async () => {
        setLoading(true);
        try {
            // Simulating additional data by fetching the same data again
            const response = await axios.get(ConsumerData);
            setUser(prevUsers => [...prevUsers, ...response.data]);
            setDisplayCount(prevCount => prevCount + 5); // Increase the display count
        } catch (error) {
            console.error('Error fetching additional data:', error);
        } finally {
            setLoading(false);
        }
    };


    const defaultImage = "Images/home-logo/home-main-logo.png";
    return (
        <div className='offset-lg-2 col-lg-8'>
            <Table className='table custom-table  align-top ms-5 mt-5 '  >

                <tbody >
                    {ConsumerData.slice(0, displayCount).map((curUser) => {
                        const { id, name, consumerID, amount } = curUser;

                        return (
                            <tr key={id} >
                                <td >{id}</td>
                                <td> <img src={defaultImage} width='30px' alt={`User ${id} Image`} /></td>
                                <td >{name}</td>
                                <td >{consumerID}</td>
                                <td >{amount}</td>
                            </tr>
                        ) })}
                </tbody>
            </Table>
            
            <div className='text-center mb-5 mt-3  rounded offset-lg-6 col-lg-2'>
                {loading && <p>Loading...</p>}
                {!loading && (
              
                    <button type="button" onClick={handleLoadMore} disabled={loading} className="btn btn-warning rounded" width="5">View More</button>
                )}
            </div>
        </div>

    )
}

export default DynamicTable

