import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { FAQdatalist1, FAQdatalist2 } from '../FAQ-data/FAQ-data';
import MyHelmet from "./Helmet";


function FAQ (){
    return(
        <>
          <MyHelmet
            title="FAQ"
            description="Welcome to my site! Explore our products and more."
            canonicalUrl="http://mysite.com/example"
        />
 
         <Container >
           <Col className='receiver text-center mt-5 text-uppercase '>
            <h2>FAQ</h2>
           </Col>

            <Row className='offset-1'>
                <Col lg={5} md={10}>
                    <Accordion>
                      
                             {FAQdatalist1.map((item, index) => (
                                <Accordion.Item eventKey={index.toString()} key={index}>
                                    <Accordion.Header className='accordian-header'>{item.question}</Accordion.Header>
                                    <Accordion.Body className='accordian-body'>{item.Answer}</Accordion.Body>
                                </Accordion.Item>
                                ))}
                        </Accordion>
                </Col>

                <Col lg={5} md={10}>
                      <Accordion >
                        <Accordion.Item eventKey="0">
        
                       {FAQdatalist2.map((item, index) => (
                                <Accordion.Item key={index}>
                                    <Accordion.Header>{item.question}</Accordion.Header>
                                    <Accordion.Body>{item.Answer}</Accordion.Body>
                                </Accordion.Item>
                                ))}
                            </Accordion.Item>
                        </Accordion>
                        </Col>
                    </Row>
                </Container>

      </>
    )
}

export default FAQ;