import { configureStore } from "@reduxjs/toolkit";
import authSlice from './redux/authSlice';
import authSliceRegister from "./redux/authSliceRegister";

const store = configureStore({
    reducer :{
        user:authSlice,
        auth:authSliceRegister

    },

})
   
export default store ;
