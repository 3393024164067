import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Container, Table } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

function ReceiveHelp() {
  return (
    <Container>
      <Col className=" receiver text-center mt-5 mb-5"><h2>RECEIVE HELP</h2></Col>

      <Col className='text-end'>
        <NavLink to="/receive-help2"><Button><FontAwesomeIcon icon={faEye}/> Receive help open</Button></NavLink>
      </Col>
      <Col lg={6} className='offset-lg-3 mt-5'>
      <Table striped bordered hover variant="transparent" className=' text-center  '>
                <thead>
                    <tr>
                        <th className='sorting text-center' colSpan={7}>2046<span className='text-primary'>/3</span><span className='text-danger'> /3,</span><span className='text-warning'> 0 /3</span></th>
                     
                    </tr>
                </thead>
                <tbody >
                    <tr>
                        <td>1</td>
                        <td>150</td>
                        <td>2</td>
                        <td>2</td>
                        <td>2</td>
                        <td>-</td>
                        <td>2</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>200</td>
                        <td>4</td>
                        <td>1</td>
                        <td>1</td>
                        <td>-</td>
                        <td>1</td>
                    </tr>
                </tbody>
            </Table>
        </Col>
    </Container>
  )
}

export default ReceiveHelp