import React from 'react'
import { Button, Card, CardTitle, Col, Container, Form } from "react-bootstrap"

function EnterOTP() {
    return (
        <Container className="">
            <Col className=' receiver text-center mt-5 text-uppercase'><h2>reset password</h2></Col>
            <Col className=" mt-5 pt-5">
                <Card style={{ width: '34rem', height: "15rem", }} className="rounded mx-auto">
                    <Card.Body>
                        <CardTitle className="text-muted text-center pt-5 mb-4">Reset your password</CardTitle>
                        <Form>
                            <Form.Group className="mb-3 me-3 ms-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="otp" placeholder="Enter OTP" />
                            </Form.Group>
                        </Form>
                        <Col lg={12} className="text-center   ">
                            <Button className="btn-block verify_otp">Verify OTP</Button>
                        </Col>
                    </Card.Body>
                </Card>
            </Col>
        </Container>
    )
}

export default EnterOTP