import { Col, Container, Form, Pagination, Row, Table } from 'react-bootstrap'

function PMF() {
  return (
    <Container className='mt-5'>
        <Col className=" receiver text-center "><h2>PMG</h2></Col>     
        <Col className=" text-center mt-5"><h5>Please transfer the PMF through any UPI and send the screenshot of your transaction amount given to the company through the form.</h5></Col>
        
        <Col>
         <Row>
            <Form className='d-flex'>
                <Form.Group as={Col} controlId="formGridNumber">
                    <Form.Label className='d-flex col-lg-3 mt-3' > Show
                        <Form.Select  className='ms-1 me-1'>
                            <option value="1">10</option>
                            <option value="2">20</option>
                            <option value="3">25</option>
                        </Form.Select>
                        Entries
                    </Form.Label>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridNumber">
                    <Form.Label className='d-flex  mt-3 offset-lg-8' > Search: <Form.Control type="search" /></Form.Label>
                            
                </Form.Group>
            </Form>
        </Row>
    </Col>

    <Table striped bordered hover variant="dark" className='mt-4 text-end pe-5'>
      <thead>
        <tr>
          <th className='sorting'>Stage</th>
          <th>Amount</th>
          <th>GST</th>
          <th>Total</th>
          <th>Status</th>
          <th>Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>₹450.00</td>
          <td>₹81.00</td>
          <td>₹531.00</td>
          <td>₹177.00</td>
          <td>30-12-2023 12:44</td>
          <td></td>
        </tr>
        <tr>
          <td>2</td>
          <td>₹100.00</td>
          <td>₹18.00 </td>
          <td>₹118.00</td>
          <td>Waiting</td>
          <td></td>
          <td> <Form.Check aria-label="option 1" /></td>
        </tr>
        <tr>
          <td>2</td>
          <td>₹100.00</td>
          <td>₹18.00 </td>
          <td>₹118.00</td>
          <td>Waiting</td>
          <td></td>
          <td> <Form.Check aria-label="option 1" /></td>
        </tr>
        <tr>
          <td>2</td>
          <td>₹100.00</td>
          <td>₹18.00 </td>
          <td>₹118.00</td>
          <td>Waiting</td>
          <td></td>
          <td> <Form.Check aria-label="option 1" /></td>
        </tr>
        <tr>
          <td>2</td>
          <td>₹100.00</td>
          <td>₹18.00 </td>
          <td>₹118.00</td>
          <td>Waiting</td>
          <td></td>
          <td> <Form.Check aria-label="option 1" /></td>
        </tr>

        <tr>
          <td>2</td>
          <td>₹100.00</td>
          <td>₹18.00 </td>
          <td>₹118.00</td>
          <td>Waiting</td>
          <td></td>
          <td> <Form.Check aria-label="option 1" /></td>
        </tr>
        <tr>
          <td>2</td>
          <td>₹100.00</td>
          <td>₹18.00 </td>
          <td>₹118.00</td>
          <td>Waiting</td>
          <td></td>
          <td> <Form.Check aria-label="option 1" /></td>
        </tr>
        <tr>
          <td>2</td>
          <td>₹100.00</td>
          <td>₹18.00 </td>
          <td>₹118.00</td>
          <td>Waiting</td>
          <td></td>
          <td> <Form.Check aria-label="option 1" /></td>
        </tr>
        <tr>
          <td>2</td>
          <td>₹100.00</td>
          <td>₹18.00 </td>
          <td>₹118.00</td>
          <td>Waiting</td>
          <td></td>
          <td> <Form.Check aria-label="option 1" /></td>
        </tr>
       
      </tbody>
    </Table>

  <Row className='mt-4'>
    <Col >
      <h6>Showing 1 to 10 of 212 entries2 rows selected</h6>
    </Col>

    <Col className='ps-2'>
     <Pagination className='justify-content-end'>
      <Pagination.First />
      <Pagination.Prev />
      <Pagination.Item>{1}</Pagination.Item>
      <Pagination.Ellipsis />

      <Pagination.Item>{10}</Pagination.Item>
      <Pagination.Item>{11}</Pagination.Item>
      <Pagination.Item active>{12}</Pagination.Item>
      <Pagination.Item>{13}</Pagination.Item>
      <Pagination.Item disabled>{14}</Pagination.Item>

      <Pagination.Ellipsis />
      <Pagination.Item>{20}</Pagination.Item>
      <Pagination.Next />
      <Pagination.Last />
    </Pagination>

    </Col>
  </Row>
      

    </Container>
  )
}

export default PMF