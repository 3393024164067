import { Button, Col, Container, Form } from 'react-bootstrap';
import { GivenTakerData } from './GivenTakerData';
import MyHelmet from './Helmet';

function Giventakers(){
    return(
    <>
         <MyHelmet
            title="Giventakers"
            description="Welcome to my site! Explore our products and more."
            canonicalUrl="http://mysite.com/example"
        />
    

   <Container>
   
            <div className=" receiver text-center text-uppercase ">
                <h1>giventakers</h1>
            </div>
            <div className="paragraph text-center mt-5">
                <h4>
                    To know more about please contact Giventaker
                </h4>
            </div>
    
            <Col className='col-lg-7 d-lg-flex ms-3 me-3'>
                    <Col md={12} lg={3} className=' offset-lg-4 mb-3 me-3'>
                    <Form.Select className="m-3 pe-4" aria-label="Select State">
                    <option>Select State</option>
                    <option value="1">One</option>
                    </Form.Select>
                    </Col>

                    <Col md={12} lg={3} className='mb-3 me-3'>
                    <Form.Select className="m-3 " aria-label="Select District">
                    <option>Select District</option>
                    <option value="1">One</option>
                    </Form.Select>
                    </Col>

                    <Col md={12} lg={3} className='mb-3 me-3'>
                    <Form.Select className="m-3" aria-label="Select Designation">
                    <option>Select Designation</option>
                    <option value="1">One</option>
                    </Form.Select>
                    </Col> 

                    <Col md={12} lg={1} className='m-3 '>
                    <Button>Search</Button>
                    </Col>
                    </Col>


     
        <div className="paragraph text-center">
            <h3>TERMS AND CONDITIONS FOR JUNIOR GIVENTAKERS AND BRAND AMBASSADORS</h3>
        </div>
        <div className=' paragraph mt-3 ms-5 me-5'>
          <ol>
              { GivenTakerData.map((item,index)=> (
                  <li className="mb-3"key={index}>{item.paragraph}</li>
              ))},

            
          </ol>
        </div>
    
 </Container>
</>
    )
}

export default Giventakers