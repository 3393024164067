export const GivenTakerData = [
    {
       paragraph :"A person who has cooperated with the giveNtake.world online helping platform becomes a giveNtake Associate when he/she understands the concept of the platform correctly and clearly and provides unconditional direct financial help to at least five of the ten consumers who have associated with the platform prior to him/her."
    },
    {
        paragraph :"giveNtake.world online helping platform gives consumers the positions of Junior giveNtaker, giveNtaker, Senior giveNtaker, and Super Senior giveNtaker",
    },
    {
        paragraph :"giveNtaker, Senior giveNtaker and Super Senior giveNtaker are officially confirmed as Brand Ambassadors of giveNtake.world online helping platform."
    },
    {
        paragraph :"giveNtaker, Senior giveNtaker and Super Senior giveNtaker are officially confirmed as Brand Ambassadors of giveNtake.world online helping platform." 
    },
    {
        paragraph :"giveNtaker, Senior giveNtaker and Super Senior giveNtaker are officially confirmed as Brand Ambassadors of giveNtake.world online helping platform." 
    },
    {
        paragraph :"giveNtaker, Senior giveNtaker andSuper Senior giveNtaker are officially confirmed as Brand Ambassadors of giveNtake.world online helping platform." 
    },
    {
        paragraph :"giveNtaker, Senior giveNtaker and Super Senior giveNtaker are officially confirmed as Brand Ambassadors of giveNtake.world online helping platform." 
    },

    
    
    
 
        
]