import { Image } from "react-bootstrap"
import { NavLink } from "react-router-dom"



 export   const notificationData = [
    {
        heading: "Join Our Telegram Group",
        Paragraph:"giventake. World on line helping Plateform से उपभोक्ताओं को सीधे 150 रुपये से 82,95,500 रुपये तक की वित्तीय सहायता , अन्य उपभोक्ताओं से प्राप्त करने के लिए आवश्यक सेवाओं और आधिकारिक सूचनाओं को सीधे प्राप्त करने के लिए उपभोक्ताओं से निवेदन है कि कंपनी के आधिकारिक टेलीग्राम समूह का सदस्य बनें। दिए लिंक को क्लिक करने टेलीग्राम मे ग्रूप जॉइन होवे।",
        image: <NavLink><Image src="https://gnt-prod.s3.ap-south-1.amazonaws.com/sourc…tsApp_Image_2023-09-14_at_1.17.42_PM_ixfb38S.jpeg"/></NavLink>,
        link: <NavLink to="LINK:%20https://t.me/+kQrmTDN7pTU1YTRl">LINK: https://t.me/+kQrmTDN7pTU1YTRl</NavLink>

    },
    {
        heading: "Join Our Telegram Group",
        Paragraph:"giventake. World on line helping Plateform से उपभोक्ताओं को सीधे 150 रुपये से 82,95,500 रुपये तक की वित्तीय सहायता , अन्य उपभोक्ताओं से प्राप्त करने के लिए आवश्यक सेवाओं और आधिकारिक सूचनाओं को सीधे प्राप्त करने के लिए उपभोक्ताओं से निवेदन है कि कंपनी के आधिकारिक टेलीग्राम समूह का सदस्य बनें। दिए लिंक को क्लिक करने टेलीग्राम मे ग्रूप जॉइन होवे।",
        image: <NavLink><Image src="https://gnt-prod.s3.ap-south-1.amazonaws.com/sourc…tsApp_Image_2023-09-14_at_1.17.42_PM_ixfb38S.jpeg"/></NavLink>,
        link: <NavLink to="LINK:%20https://t.me/+kQrmTDN7pTU1YTRl">LINK: https://t.me/+kQrmTDN7pTU1YTRl</NavLink>

    },
    {
        heading: "Join Our Telegram Group",
        Paragraph:"giventake. World on line helping Plateform से उपभोक्ताओं को सीधे 150 रुपये से 82,95,500 रुपये तक की वित्तीय सहायता , अन्य उपभोक्ताओं से प्राप्त करने के लिए आवश्यक सेवाओं और आधिकारिक सूचनाओं को सीधे प्राप्त करने के लिए उपभोक्ताओं से निवेदन है कि कंपनी के आधिकारिक टेलीग्राम समूह का सदस्य बनें। दिए लिंक को क्लिक करने टेलीग्राम मे ग्रूप जॉइन होवे।",
        image: <NavLink><Image src="https://gnt-prod.s3.ap-south-1.amazonaws.com/sourc…tsApp_Image_2023-09-14_at_1.17.42_PM_ixfb38S.jpeg"/></NavLink>,
        link: <NavLink to="LINK:%20https://t.me/+kQrmTDN7pTU1YTRl">LINK: https://t.me/+kQrmTDN7pTU1YTRl</NavLink>

    },
    {
        heading: "Join Our Telegram Group",
        Paragraph:"giventake. World on line helping Plateform से उपभोक्ताओं को सीधे 150 रुपये से 82,95,500 रुपये तक की वित्तीय सहायता , अन्य उपभोक्ताओं से प्राप्त करने के लिए आवश्यक सेवाओं और आधिकारिक सूचनाओं को सीधे प्राप्त करने के लिए उपभोक्ताओं से निवेदन है कि कंपनी के आधिकारिक टेलीग्राम समूह का सदस्य बनें। दिए लिंक को क्लिक करने टेलीग्राम मे ग्रूप जॉइन होवे।",
        image: <NavLink><Image src="https://gnt-prod.s3.ap-south-1.amazonaws.com/sourc…tsApp_Image_2023-09-14_at_1.17.42_PM_ixfb38S.jpeg"/></NavLink>,
        link: <NavLink to="LINK:%20https://t.me/+kQrmTDN7pTU1YTRl">LINK: https://t.me/+kQrmTDN7pTU1YTRl</NavLink>

    },
    {
        heading: "Join Our Telegram Group",
        Paragraph:"giventake. World on line helping Plateform से उपभोक्ताओं को सीधे 150 रुपये से 82,95,500 रुपये तक की वित्तीय सहायता , अन्य उपभोक्ताओं से प्राप्त करने के लिए आवश्यक सेवाओं और आधिकारिक सूचनाओं को सीधे प्राप्त करने के लिए उपभोक्ताओं से निवेदन है कि कंपनी के आधिकारिक टेलीग्राम समूह का सदस्य बनें। दिए लिंक को क्लिक करने टेलीग्राम मे ग्रूप जॉइन होवे।",
        image: <NavLink><Image src="https://gnt-prod.s3.ap-south-1.amazonaws.com/sourc…tsApp_Image_2023-09-14_at_1.17.42_PM_ixfb38S.jpeg"/></NavLink>,
        link: <NavLink to="LINK:%20https://t.me/+kQrmTDN7pTU1YTRl">LINK: https://t.me/+kQrmTDN7pTU1YTRl</NavLink>

    }

]




