import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Dashboard from './components/Dashboard';
import DigitalDiscountsPoints from './components/Digital-Discounts-Points';
import EnterOTP from './components/Enter-OTP';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import GiveHelp from './components/Give-Help';
import Giventakers from './components/Giventakers';
import Home from './components/Home';
import Invoice from './components/Invoice';
import InvoiceBill from './components/InvoiceBill';
import KYC from './components/KYC';
import Legal from './components/Legal';
import Login from './components/Login';
import Menubar from './components/Menubar';
import MyInvitation from './components/My-Invitation';
import MyAccount from './components/MyAccount';
import MyProfile from './components/MyProfile';
import Notification from './components/Notification';
import PMF from './components/PMF';
import PrivacyandPolicy from './components/Privacy-and-Policy';
import ReceiveHelp from './components/Receive-Help';
import Register from './components/Register';
import ResetPasswords from './components/Reset-Passwords';
import StarRanking from './components/Star-Ranking';
import Support from './components/Support';
import TermsandConditions from './components/Terms-and-Condition';
import TopReceivers from './components/Top-Receivers';
import TransactionHistory from './components/Transaction-History';
import Receivehelp2 from './components/receive-help2';
import Delete from './support-page-data/Delete';
import Transfer from './support-page-data/Transfer';
import Update from './support-page-data/Update';

function App() {
  return (
    <div className='app bg-primary'>
      <BrowserRouter>
       <Menubar/>
        <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='top-receivers' element={<TopReceivers/>}/>
        <Route path='giventakers' element={<Giventakers/>}/>
        <Route path='support' element={<Support/>}/>
        <Route path='start-ranking' element={<StarRanking/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='about' element={<About/>}/>
        <Route path='terms-and-conditions' element={<TermsandConditions/>}/>
        <Route path='Privacy-Policy' element={<PrivacyandPolicy/>}/>
        <Route path='faq' element={<FAQ/>}/>    
        <Route path='transfer' element={<Transfer/>}/>
        <Route path='update' element={<Update/>}/>
        <Route path='delete' element={<Delete/>}/>
        <Route path='legal' element={<Legal/>}/>
        <Route path='my-account' element={<MyAccount/>}/>
        <Route path='my-profile' element={<MyProfile/>}/>
        <Route path='dashboard' element={<Dashboard/>}/>
        <Route path='pmf' element={<PMF/>}/>
        <Route path='give-help' element={<GiveHelp/>}/>
        <Route path='invoice' element={<Invoice/>}/>
        <Route path='invoice-bill' element={<InvoiceBill/>}/>
        <Route path='my-invitation' element={<MyInvitation/>}/>
        <Route path='receive-help' element={<ReceiveHelp/>}/>
        <Route path='receive-help2' element={<Receivehelp2/>}/>
        <Route path='transaction-history' element={<TransactionHistory/>}/>
        <Route path='gnt' element={<DigitalDiscountsPoints/>}/>
        <Route path='reset-password' element={<ResetPasswords/>}/>
        <Route path='enter-otp' element={<EnterOTP/>}/>
        <Route path='notification' element={<Notification/>}/>
        <Route path='kyc' element={<KYC/>}/>
        <Route path='login' element={<Login/>}/>
        <Route path='register' element={<Register/>}/>
      </Routes>
    <Footer/>
  </BrowserRouter>
 </div>
);
}

export default App;
