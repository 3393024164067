import { Card, Col, Container, NavLink, Row } from 'react-bootstrap'

function MyAccount() {
  return (
    <Container>
      <h2 className='receiver text-uppercase mt-5 mb-5 text-center'>hii, ravita raut</h2>

      <Row>
        <Col lg={4}>
          <Card style={{ height: '20rem' }}>
            <Card.Body>
              <h6 className='text-muted text-uppercase text-center'>my account status</h6>

              <Row className='ps-5 t'>
                <Col lg={5}>Consumer Name</Col>
                <Col lg={2} className='text-center'>:</Col>
                <Col lg={5}>Ravita Raut</Col>
              </Row>
              <Row className='ps-5 '>
                <Col lg={5}>Consumer No</Col>
                <Col lg={2} className='text-center'>:</Col>
                <Col lg={5}>130122023123537</Col>
              </Row>

              <Row className='ps-5 t'>
                <Col lg={5}>Total Give Help</Col>
                <Col lg={2} className='text-center'>:</Col>
                <Col lg={5}>₹3150.00</Col>
              </Row>

              <Row className='ps-5 t'>
                <Col lg={5}>Total PMF</Col>
                <Col lg={2} className='text-center'>:</Col>
                <Col lg={5}>₹600.00</Col>
              </Row>

              <Row className='ps-5 t'>
                <Col lg={5}>Total Tax</Col>
                <Col lg={2} className='text-center'>:</Col>
                <Col lg={5}>₹108.00</Col>
              </Row>

              <Row className='ps-5 t'>
                <Col lg={5}>Total Received Help</Col>
                <Col lg={2} className='text-center'>:</Col>
                <Col lg={5}>₹500.00</Col>
              </Row>

              <Row className='ps-5 t'>
                <Col lg={5}>Registration Time</Col>
                <Col lg={2} className='text-center'>:</Col>
                <Col lg={5}>30-12-2023 12:35 PM</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card style={{ height: '18rem' }}>
            <Card.Body>
            <h6 className='text-muted text-uppercase text-center'>Designation</h6>
            <p className=' text-uppercase text-center'>Giventake Associate</p>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card style={{ height: '18rem' }}>
            <Card.Body><h3 className='text-muted text-uppercase text-center'>20 Days Left</h3></Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={4} className='mt-3'>
          <Card style={{ height: '18rem' }}>
            <Card.Body>
              <h6 className='text-center text-uppercase text-muted'>invitation link</h6>
             <Col className=' btn offset-lg-4 bg-primary mt-5'>
                <NavLink to="" className='text-white' >Invitation Link</NavLink>
              </Col>
       
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
  </Container>
  )
}

export default MyAccount