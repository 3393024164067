import { useAuth0 } from "@auth0/auth0-react";
import { faCog, faLock, faSignInAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';


function OffcanvasExample() {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
 


  return (
    <div className='menubar bg-primary shadow-lg '>
      <Navbar collapseOnSelect expand="lg" className="mb-3 sticky-top">
        <Container fluid>
          <Navbar.Brand to="/" className='logo nav-link ms-5 ms-lg-5' as={NavLink}>GIVEANDTAKE.WORLD</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="d-lg-none">
            <Nav className="nav justify-content-start flex-grow-1 pe-3 pe-lg-5 text-start">
              <Nav className=" nav justify-content-start flex-grow-1 pe-3 pe-lg-5 text-start">
                <NavLink className="nav-link pe-4 ms-lg-5 ps-lg-5" to="/">Home</NavLink>

                <NavDropdown
                  title="Info" className=' dropdown' data-toggle="collapse">
                  <NavDropdown.Item as={NavLink} to="about">About</NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="legal">Legal</NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="terms-and-conditions">T & C</NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="privacy-policy">Privacy Policy</NavDropdown.Item>
                  <NavDropdown.Item as={NavLink} to="faq">FAQ</NavDropdown.Item>

                </NavDropdown>
                <NavLink className="nav-link pe-4" to="top-receivers">Top Receivers</NavLink>
                <NavLink className="nav-link pe-4" to="giventakers">Giventakers</NavLink>
                <NavLink className="nav-link pe-3" to="support">Support</NavLink>
                <NavLink className="nav-link pe-4" to="start-ranking">Star Ranking</NavLink>
                <NavLink className="nav-link pe-4" to="contact">Contact</NavLink>
              </Nav>
            </Nav>

            {isAuthenticated ? (
              <NavDropdown
                title="Ravina Raut"
                className=' dropdown-centered pe-lg-5 me-lg-5'
                data-toggle="collapse"
              >
                <NavDropdown.Item as={NavLink} to="dashboard"> <FontAwesomeIcon icon={faUser} />  Dashboard</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="notification"><FontAwesomeIcon icon={faUser} /> Notification</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="pmf"> <FontAwesomeIcon icon={faCog} size="1px" /> PMF</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="give help"><FontAwesomeIcon icon={faLock} size="1px" /> Give Help</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="invoice"><FontAwesomeIcon icon={faLock} size="1px" /> Invoice</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="my-invitation"><FontAwesomeIcon icon={faLock} size="1px" /> My Invitation</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="receive-help"><FontAwesomeIcon icon={faLock} size="1px" /> Receive Help</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="kyc"><FontAwesomeIcon icon={faLock} size="1px" /> KYC</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="transaction-history"><FontAwesomeIcon icon={faLock} size="1px" /> Transaction History</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="my-profile"><FontAwesomeIcon icon={faLock} size="1px" /> My Profile</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="reset-password"><FontAwesomeIcon icon={faLock} size="1px" /> Reset Password</NavDropdown.Item>
                <Dropdown.Divider />
                <NavDropdown.Item as={NavLink} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}><FontAwesomeIcon icon={faSignInAlt} />  Logout</NavDropdown.Item>
             </NavDropdown> ) : (
              // <NavDropdown title="Login"  className=' dropdown-centered pe-lg-5'>
                <NavLink className="nav-link text-center  pe-lg-5"  to="login">
                  <FontAwesomeIcon icon={faUser} /> Login
                </NavLink>
             
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div>
  );
}

export default OffcanvasExample;
