import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';

function StarRanking() {
  return (
    <Container className='mt-5'>
      <Col><h2 className='receiver text-uppercase text-center mb-5'>star ranking</h2></Col>

      <Col className='d-flex offset-lg-8 '>
        <Col className='me-5 ms-4'>
        <Form.Select aria-label="Default select example">
          <option>Select start rank</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </Form.Select>
        </Col>
        <Col className='me-3'>
        <Button>Search</Button>
        </Col>
      </Col>

      <Col className='paragraph  mt-5'>
       <ul>
        <li>To achieve star ranking in giventake, a member has to give-help to ten stages.</li>
        <li>Then he/she becomes a STAR.</li>
        <li>When his first stage child members enter into the star ranking or becomes a star, the former becomes ONE STAR.</li>
        <li>Likewise the system duplicates and the former finally becomes TEN STAR Rank holder.</li>
        <li>When the former reaches the Ten Star ranking he retires automatically.</li>
       </ul>
      </Col>

      <Row className='mt-5'>
        <Col lg={3}>
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <Row className='d-flex '>
                <Col xs={8}>
                  <h6 className='text-uppercase text-muted font-16'>deepak c g</h6>
                  <h5 className='text-secondary mt-4'>ID: 1545831733</h5>
                </Col>
                <Col xs={4} className='text-end '>
                  <i className='bg-warning star-rank border'> <FontAwesomeIcon icon={faStar} />1 </i>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={3}>
          <Card style={{ width:'100%' }}>
            <Card.Body>
              <Row className='d-flex '>
                <Col xs={8}>
                  <h6 className='text-uppercase text-muted font-16'>deepak c g</h6>
                  <h5 className='text-secondary mt-4'>ID: 1545831733</h5>
                </Col>
                <Col xs={4} className='text-end '>
                  <i className='bg-warning star-rank border'> <FontAwesomeIcon icon={faStar} />1 </i>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={3}>
          <Card style={{ width:'100%' }}>
            <Card.Body>
              <Row className='d-flex '>
                <Col xs={8}>
                  <h6 className='text-uppercase text-muted font-16'>deepak c g</h6>
                  <h5 className='text-secondary mt-4'>ID: 1545831733</h5>
                </Col>
                <Col xs={4} className='text-end '>
                  <i className='bg-warning star-rank border'> <FontAwesomeIcon icon={faStar} />1 </i>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={3}>
          <Card style={{ width: '100%' }}>
            <Card.Body>
              <Row className='d-flex '>
                <Col xs={8}>
                  <h6 className='text-uppercase text-muted font-16'>deepak c g</h6>
                  <h5 className='text-secondary mt-4'>ID: 1545831733</h5>
                </Col>
                <Col xs={4} className='text-end '>
                  <i className='bg-warning star-rank border'> <FontAwesomeIcon icon={faStar} />1 </i>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </Container>

  )
}

export default StarRanking;