import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import NotificationCard from '../components/NotificationCard';
import { notificationData } from '../components/NotificationData';

function Notification() {
  return (
    <Container >
      <Row>
      <Col xs={12}>
  
       <Col><h2 className=" receiver text-uppercase text-center mt-5">Notification</h2></Col>
      <Col  className="mt-5">
        {notificationData.map((item, index) => (
          <NotificationCard
            key={index}
            heading={item.heading}
            paragraph={item.Paragraph}
            image={item.image}
            link={item.link}
          />
        ))}
      </Col>
      <Col lg={12} className="text-center">
        <Button className="">View</Button>
      </Col>
      </Col>
      </Row>
    </Container>
  );
}

export default Notification;
