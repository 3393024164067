import MyHelmet from "./Helmet"

function PrivacyandPolicy() {
  return (
    <>
      <MyHelmet
        title="Privacy and Policy"
        description="Welcome to my site! Explore our products and more."
        canonicalUrl="http://mysite.com/example"
      />
      <div className='container mt-5 pt-5'>

        <div className='receiver text-center mt-5 text-uppercase '>
          <h2>privacy and policy</h2>
        </div>

        <div className=' paragraph mb-0 mt-5 offset-lg-2 col-lg-8 '>
          <p >At giventake.world we are committed to protecting your privacy. Please read on for more details about our website privacy policy.
            Controllers of personal information</p>
          <p>Any personal information provided or to be gathered by giventake.world is controlled by The giventake.
            What information do we collect?</p>
          <p>Any personal information provided or to be gathered by giventake.world is controlled by The giventake.
            What information do we collect?</p>
        </div>
      </div>
    </>
  )
}

export default PrivacyandPolicy